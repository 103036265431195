import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { filter } from "ramda";
import { IHttpIndexResponse } from "../../Entities/Models/IHttpIndexResponse";
import { IPatient } from "../../Entities/Models/IPatient";
import http from "../../Services/http";
import { getActiveProvider } from "../../utils";
import {
  fromHttpPatient,
  IHttpPatientResponse,
  toHttpPatient,
} from "../epics/httpResponseTypes/patientsResponseType";
import { AppThunk } from "../store";
import ActionStatusType from "./actionStatusType";

type CreatePatientStatusType = ActionStatusType & {
  createdPatientId?: number | null;
};

type UpdatePatientStatusType = ActionStatusType & {
  updatedPatientId?: number | null;
};

type Pagination = {
  currentPage: number;
  perPage: number;
  search?: string;
};

export type PatientsStateType = {
  queries: Record<string, { items: Array<number>; total: number }>;
  loading: boolean;
  data: Record<number, IPatient>;
  createStatus: CreatePatientStatusType;
  updateStatus: UpdatePatientStatusType;
  archiveStatus: ActionStatusType;
  pagination: Pagination;
};

const initialState: PatientsStateType = {
  queries: {},
  loading: false,
  data: {},
  createStatus: {
    loading: false,
    error: "",
    success: "",
    createdPatientId: null,
  },
  updateStatus: {
    loading: false,
    error: "",
    success: "",
    updatedPatientId: null,
  },
  archiveStatus: {
    loading: false,
    error: "",
    success: "",
  },
  pagination: {
    currentPage: 0,
    perPage: 5,
  },
};

const patientRecords = createSlice({
  name: "patient-records",
  initialState,
  reducers: {
    invalidateAllPacientQueries(state) {
      state.queries = {};
    },
    removePatient(state, action: PayloadAction<number>) {
      state.data = filter(
        (patient) => patient.id !== action.payload,
        state.data
      );
    },
    setPatientsPagination(state, action: PayloadAction<Pagination>) {
      state.pagination = action.payload;
    },
    setLoadingPatientsQuery(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPatients(state, action: PayloadAction<Array<IPatient> | IPatient>) {
      const newPatients = [action.payload]
        .flat()
        .reduce(
          (records, patient) => ({ ...records, [patient.id]: patient }),
          {}
        );
      state.data = { ...state.data, ...newPatients };
    },
    removePatients(state, action: PayloadAction<number | Array<number>>) {
      const patientsIdToRemove = [action.payload].flat();
      state.data = filter(
        (patient) => !patientsIdToRemove.includes(patient.id),
        state.data
      );
    },
    setCreatePatientStatus(
      state,
      action: PayloadAction<CreatePatientStatusType>
    ) {
      state.createStatus = action.payload;
    },
    setUpdatePatientStatus(
      state,
      action: PayloadAction<UpdatePatientStatusType>
    ) {
      state.updateStatus = action.payload;
    },
    setArchivePatientStatus(state, action: PayloadAction<ActionStatusType>) {
      state.archiveStatus = action.payload;
    },
    setPatientsQuery(
      state,
      action: PayloadAction<{
        query: string;
        items: Array<number>;
        total: number;
      }>
    ) {
      const { query, items, total } = action.payload;
      state.queries = { ...state.queries, [query]: { items, total } };
    },
  },
});

export const {
  setPatients,
  removePatients,
  setCreatePatientStatus,
  setUpdatePatientStatus,
  setArchivePatientStatus,
  setLoadingPatientsQuery,
  setPatientsQuery,
  setPatientsPagination,
  invalidateAllPacientQueries,
  removePatient,
} = patientRecords.actions;

const patientRecordsReducer = patientRecords.reducer;
export default patientRecordsReducer;

export const getPatientsQuery = (pagination: Pagination) =>
  JSON.stringify(pagination);

export function createPatient(patient: IPatient): AppThunk {
  return async function (dispatch) {
    dispatch(setCreatePatientStatus({ loading: true, error: "", success: "" }));
    const _http = await http();
    try {
      const response = await _http.post<IHttpPatientResponse>(
        "/patients",
        toHttpPatient(patient)
      );
      const createdPatient = fromHttpPatient(response.data);
      dispatch(setPatients(createdPatient));
      dispatch(invalidateAllPacientQueries());
      dispatch(
        setCreatePatientStatus({
          loading: false,
          error: "",
          success: "Patient was created sucessfully",
          createdPatientId: createdPatient.id,
        })
      );
    } catch {
      dispatch(
        setCreatePatientStatus({
          loading: false,
          error: "There was an error creating the patient",
          success: "",
        })
      );
    }
  };
}

export function updatePatient(patient: IPatient): AppThunk {
  return async function (dispatch) {
    dispatch(setUpdatePatientStatus({ loading: true, error: "", success: "" }));
    const _http = await http();
    try {
      const response = await _http.put<IHttpPatientResponse>(
        `/patients/${patient.id}`,
        toHttpPatient(patient)
      );
      const updatedPatient = fromHttpPatient(response.data);
      dispatch(setPatients(updatedPatient));
      dispatch(
        setUpdatePatientStatus({
          loading: false,
          error: "",
          success: "Patient was updated sucessfully",
          updatedPatientId: updatedPatient.id,
        })
      );
    } catch {
      dispatch(
        setUpdatePatientStatus({
          loading: false,
          error: "There was an error updating the patient",
          success: "",
        })
      );
    }
  };
}

export function archivePatient(id: number): AppThunk {
  return async function (dispatch) {
    dispatch(
      setArchivePatientStatus({
        loading: true,
        error: "",
        success: "",
      })
    );

    const _http = await http();
    try {
      await _http.delete(`/patients/${id}`);
      dispatch(invalidateAllPacientQueries());
      dispatch(removePatient(id));
      dispatch(
        setArchivePatientStatus({
          loading: false,
          error: "",
          success: "Successfully archived patient.",
        })
      );
    } catch {
      dispatch(
        setArchivePatientStatus({
          loading: false,
          error: "There was an error archiving the patient.",
          success: "",
        })
      );
    }
  };
}

export function fetchPatients(
  offset = 0,
  limit = 5,
  search?: string
): AppThunk {
  return async function (dispatch, state) {
    const activePrvider = getActiveProvider(state());
    dispatch(setLoadingPatientsQuery(true));
    const _http = await http();
    const response = await _http.get<IHttpIndexResponse<IHttpPatientResponse>>(
      "/patients",
      {
        params: {
          provider_id: activePrvider?.id,
          offset,
          limit,
          search,
        },
      }
    );
    dispatch(setPatients(response.data.data.map((p) => fromHttpPatient(p))));
    dispatch(
      setPatientsQuery({
        query: getPatientsQuery({
          currentPage: Math.floor(offset / limit),
          perPage: limit,
          search,
        }),
        items: response.data.data.map((p) => p.id),
        total: response.data.total,
      })
    );
    dispatch(setLoadingPatientsQuery(false));
  };
}
