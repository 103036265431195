import { PostProviderRequestType } from "../epics/httpResponseTypes/postProviderRequestType";
import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { filter } from "ramda";
import { IProvider } from "../../Entities/Models/IProvider";
import ActionStatusType from "./actionStatusType";
import { PutProviderRequestType } from "../epics/httpResponseTypes/putProviderRequest";

type CreateProviderStatusType = ActionStatusType & {
  createdProviderId?: number | null;
};

type UpdateProviderStatusType = ActionStatusType & {
  updatedProviderId?: number | null;
};

export type ProvidersStateType = {
  data: Record<number, IProvider>;
  createStatus: CreateProviderStatusType;
  updateStatus: UpdateProviderStatusType;
};

const initialState: ProvidersStateType = {
  data: {},
  createStatus: {
    loading: false,
    error: "",
    success: "",
    createdProviderId: null,
  },
  updateStatus: {
    loading: false,
    error: "",
    success: "",
    updatedProviderId: null,
  },
};

const providerRecords = createSlice({
  name: "provider-records",
  initialState,
  reducers: {
    setProviders(state, action: PayloadAction<Array<IProvider> | IProvider>) {
      const newProviders = [action.payload]
        .flat()
        .reduce(
          (records, provider) => ({ ...records, [provider.id]: provider }),
          {}
        );
      state.data = { ...state.data, ...newProviders };
    },
    removeProviders(state, action: PayloadAction<number | Array<number>>) {
      const providerIdsToRemove = [action.payload].flat();
      state.data = filter(
        (provider) => !providerIdsToRemove.includes(provider.id),
        state.data
      );
    },
    archiveProvider(state, action: PayloadAction<number>) {},
    setCreateProviderStatus(
      state,
      action: PayloadAction<CreateProviderStatusType>
    ) {
      state.createStatus = action.payload;
    },
    createProvider(state, action: PayloadAction<PostProviderRequestType>) {
      state.createStatus.loading = true;
    },
    setUpdateProviderStatus(
      state,
      action: PayloadAction<UpdateProviderStatusType>
    ) {
      state.updateStatus = action.payload;
    },
    updateProvider(
      state,
      action: PayloadAction<{ data: PutProviderRequestType; id: number }>
    ) {
      state.updateStatus.loading = true;
    },
  },
});

export const {
  setProviders,
  removeProviders,
  archiveProvider,
  setCreateProviderStatus,
  createProvider,
  setUpdateProviderStatus,
  updateProvider,
} = providerRecords.actions;

export default providerRecords.reducer;
