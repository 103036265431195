export enum Sex {
  Male = "Male",
  Female = "Female",
}

export interface IPatient {
  id: number;
  firstName: string;
  lastName: string;
  middleNameInitial?: string;
  sex: Sex;
  birthDate: Date;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  accountNo?: string;
}

export function patientName(patient: IPatient) {
  return (
    patient.firstName +
    ", " +
    patient.lastName +
    (patient.middleNameInitial ? ", " + patient.middleNameInitial : "")
  );
}
