import { IPatient, Sex } from "../../../Entities/Models/IPatient";

export interface IHttpPatientResponse {
  id: number;
  firstName: string;
  lastName: string;
  middleNameInitial?: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  accountNumber: string;
  providerId: number;
  sex: 0 | 1;
  dateOfBirth: string;
}

export interface IHttpPatientRequest {
  firstName: string;
  lastName: string;
  middleNameInitial?: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  accountNumber: string;
  sex: 0 | 1;
  dateOfBirth: string;
}

export function toHttpPatient(patient: IPatient): IHttpPatientRequest {
  return {
    ...patient,
    address: patient.street,
    zipcode: patient.zipCode,
    accountNumber: patient.accountNo || "0",
    dateOfBirth: patient.birthDate.toISOString(),
    sex: patient.sex === Sex.Male ? 0 : 1,
  };
}

export function fromHttpPatient(patient: IHttpPatientResponse): IPatient {
  return {
    id: patient.id,
    firstName: patient.firstName,
    lastName: patient.lastName,
    middleNameInitial: patient.middleNameInitial,
    accountNo: patient.accountNumber,
    street: patient.address,
    city: patient.city,
    state: patient.state,
    zipCode: patient.zipcode,
    sex: patient.sex === 0 ? Sex.Male : Sex.Female,
    birthDate: new Date(patient.dateOfBirth),
  };
}
