import { combineReducers } from "@reduxjs/toolkit";
import authSliceReducer from "./slices/auth.slice";
import adminSliceReducer from "./slices/admin.slice";
import providersSliceReducer from "./slices/provider-records.slice";
import patientRecordsReducer from "./slices/patient-records.slice";

const rootReducer = combineReducers({
  auth: authSliceReducer,
  admin: adminSliceReducer,
  providers: providersSliceReducer,
  patients: patientRecordsReducer,
});

export default rootReducer;
