import * as Msal from "msal";
import { getMsalConfig } from "../Config/msal";

const msalConfig = getMsalConfig();

export class Auth {
  constructor(public msal: Msal.UserAgentApplication) {}

  isLoggedIn(): boolean {
    return Boolean(this.msal.getAccount());
  }

  account(): Msal.Account {
    return this.msal.getAccount();
  }

  getEmail(): string {
    return this.account().idTokenClaims["emails"][0];
  }

  setRedirectCallback(
    callback: (param1: Msal.AuthError, param2?: Msal.AuthResponse) => void
  ) {
    this.msal.handleRedirectCallback(callback);
  }

  editProfile(redirectToPath: string): void {
    this.msal.acquireTokenRedirect({
      authority: msalConfig.editProfileAuthority,
      account: this.account(),
      scopes: [msalConfig.scopes.userImpersonation],
      forceRefresh: true,
      state: redirectToPath,
    });
  }

  resetPassword(redirectToPath: string): void {
    this.msal.authority = msalConfig.passwordResetAuthority;
    this.msal.loginRedirect({
      authority: msalConfig.passwordResetAuthority,
      forceRefresh: true,
      state: "password-reset",
    });
  }

  login(redirectToPath?: string): void {
    this.msal.loginRedirect({
      state: redirectToPath ?? "/",
    });
  }

  register(): void {
    this.msal.authority = msalConfig.signupAuthority;
    this.msal.loginRedirect({
      state: "/",
    });
  }

  simpleSignup(email: string): void {
    this.msal.authority = msalConfig.simpleSignupAuthority;

    const loginRequest: Msal.AuthenticationParameters = {
      state: email,
    };

    this.msal.loginRedirect(loginRequest);
  }

  logout(): void {
    this.msal.logout();
  }

  public accessTokenRequest = {
    scopes: [msalConfig.scopes.noop],
    account: this.account(),
    redirectUri: window.location.origin + "/msal",
  };

  async getAccessToken() {
    try {
      const { accessToken } = await this.msal.acquireTokenSilent(
        this.accessTokenRequest
      );
      return accessToken;
    } catch (error) {
      if (error.message.includes("login is required")) {
        this.login(window.location.pathname);
        return undefined;
      }

      if (error.message.includes("AADB2C90077")) {
        throw new Error("TPC not supported");
      }
      this.msal.acquireTokenRedirect(this.accessTokenRequest);
    }
  }
}

let _auth: Auth | null = null;

export default function auth(): Auth {
  if (!_auth) {
    _auth = new Auth(
      new Msal.UserAgentApplication({
        auth: {
          clientId: msalConfig.clientId,
          authority: msalConfig.signupSigninAuthority,
          validateAuthority: false,
          navigateToLoginRequestUrl: false,
          redirectUri: window.location.origin,
          postLogoutRedirectUri: window.location.origin,
        },
        cache: {
          storeAuthStateInCookie: false,
          cacheLocation: "sessionStorage",
        },
      })
    );
  }

  return _auth;
}
