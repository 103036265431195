import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import auth from "./Services/auth";
import "./index.sass";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppThemeProvider from "./Providers/AppThemeProvider";
import { any } from "ramda";
import { SnackbarProvider } from "notistack";
import { Provider as ReduxProvider } from "react-redux";
import store from "./store/store";

const Logout = React.lazy(() => import("./Pages/Logout"));
const SignUp = React.lazy(() => import("./Pages/SignUp"));
const ResetPass = React.lazy(() => import("./Pages/ResetPass"));
const Msal = React.lazy(() => import("./Pages/Msal"));
const Login = React.lazy(() => import("./Pages/Login"));
const Test = React.lazy(() => import("./Pages/Test"));
const App = React.lazy(() => import("./Pages/App/App"));
const LandingPage = React.lazy(() => import("./Pages/LandingPage/LandingPage"));

var browserHystory = createBrowserHistory();

auth().setRedirectCallback((err, res) => {
  if (err) {
    const forgotPassword = () => err.message.includes("AADB2C90118");
    const cancelled = () => err.message.includes("AADB2C90091");

    if (forgotPassword()) {
      auth().resetPassword("/");
      return;
    }

    browserHystory.push("/");
    if (!cancelled() && !forgotPassword()) {
      console.error(err);
    }
  }

  if (res && res.accountState) {
    if (res.accountState === "password-reset") {
      auth().logout();
      return;
    }

    if (
      res.accountState === "/reset_password" ||
      res.accountState === "reset_password"
    ) {
      browserHystory.push("/");
      return;
    }
    if (
      any(
        (p) => res.accountState.includes(p),
        [
          "/",
          "dashboard",
          "calculator",
          "provider",
          "patients",
          "cases",
          "bills",
          "logout",
          "home",
        ]
      )
    ) {
      browserHystory.push(res.accountState);
      return;
    }

    browserHystory.push("/");
  }
});

const Root: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tpcNotSupported, setTpcNotSupported] = useState(false);

  useEffect(() => {
    if (auth().isLoggedIn()) {
      (async () => {
        setIsLoading(true);
        try {
          await auth().getAccessToken();
        } catch (e) {
          if (e.message === "TPC not supported") {
            setTpcNotSupported(true);
          }
        } finally {
          setIsLoading(false);
        }
      })();
    }

    //eslint-ingore-next-line
  }, []);

  if (isLoading) {
    return (
      <Box
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={70} />
      </Box>
    );
  }

  const ServiceWorkerProvider = auth().isLoggedIn()
    ? React.lazy(() => import("./Providers/ServiceWorkerProvider"))
    : React.Fragment;

  if (tpcNotSupported) {
    const ThirdPartyCookiesRequired = React.lazy(
      () => import("./Pages/ThirdPartyCookiesRequired")
    );
    return <ThirdPartyCookiesRequired />;
  }

  return (
    <>
      <React.Suspense fallback={<div>loading</div>}>
        <Router history={browserHystory}>
          <ReduxProvider store={store}>
            <ServiceWorkerProvider>
              <AppThemeProvider>
                <SnackbarProvider>
                  <Switch>
                    <Route path="/home" component={LandingPage} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/SignUp" component={SignUp} />
                    <Route path="/reset_password" component={ResetPass} />
                    <Route path="/msal" component={Msal} />
                    <Route path="/login" component={Login} />
                    <Route path="/test" component={Test}></Route>
                    <Route path="/" component={App} />
                  </Switch>
                </SnackbarProvider>
              </AppThemeProvider>
            </ServiceWorkerProvider>
          </ReduxProvider>
        </Router>
      </React.Suspense>
    </>
  );
};

function render() {
  ReactDOM.render(<Root />, document.getElementById("root"));
}

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./Pages/App/App", () => {
    console.log("Accepting the updateg App module");
    render();
  });
}
