import { Action, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { createEpicMiddleware } from "redux-observable";
import rootEpic from "./epics";
import auth from "../Services/auth";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import rootReducer from "./root-reducer";

const epicMiddleware = createEpicMiddleware();

const middleware: any[] = [epicMiddleware, thunk];
if (auth().isLoggedIn()) {
}

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./root-reducer", () => {
    const newRootReducer = require("./root-reducer").default;
    store.replaceReducer(newRootReducer);
  });
}

epicMiddleware.run(rootEpic);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () =>
  useDispatch<ThunkDispatch<RootState, null, Action<string>>>();

export default store;
