import { IUser } from "../../../Entities/Models/IProvider";
type GetUserResponseType = {
  id: number;
  b2cId: string;
  email: string;
  firstName: string;
  lastName: string;
  type: "Admin" | "ProviderAdmin" | "ProviderContact";
  typeId: number;
  validatedEmail: string | null;
};

export const mapUserResponseToIUser = (
  response: GetUserResponseType
): IUser => ({
  ...response,
  validatedEmail: response.validatedEmail
    ? new Date(response.validatedEmail)
    : undefined,
});

export default GetUserResponseType;
