import { getEnvironment } from "../utils";

export interface MsalConfig {
  clientId: string;
  signupSigninAuthority: string;
  signupAuthority: string;
  editProfileAuthority: string;
  passwordResetAuthority: string;
  authorizationAuthority: string;
  simpleSignupAuthority: string;
  scopes: {
    userImpersonation: string;
    noop: string;
  };
}

const prodMsalConfig: MsalConfig = {
  clientId: "d5dfa2f6-d72f-4d1d-b63c-2a4e297c6d78",
  simpleSignupAuthority:
    "https://fecaidp.b2clogin.com/tfp/fecaidpdev.onmicrosoft.com/B2C_1_feca_signup_v2",
  signupSigninAuthority:
    "https://fecaidp.b2clogin.com/tfp/fecaidp.onmicrosoft.com/B2C_1_feca_signupsignin_v2",
  signupAuthority:
    "https://fecaidp.b2clogin.com/tfp/fecaidp.onmicrosoft.com/B2C_1_signup_wth_provider_creation",
  editProfileAuthority:
    "https://fecaidp.b2clogin.com/tfp/fecaidp.onmicrosoft.com/B2C_1_feca_profile_v2",
  passwordResetAuthority:
    "https://fecaidp.b2clogin.com/tfp/fecaidp.onmicrosoft.com/B2C_1_feca_passwordreset",
  authorizationAuthority:
    "https://fecaidp.b2clogin.com/fecaidp.onmicrosoft.com/oauth2/v2.0/token?p=B2C_1_feca_signupsignin_v2",
  scopes: {
    userImpersonation: "https://fecaidp.onmicrosoft.com/api/user_impersonation",
    noop: "https://fecaidp.onmicrosoft.com/api.fecabill.com/noop",
  },
};

const devMsalConfig: MsalConfig = {
  clientId: "607f915c-35d7-4579-a001-861411bda531",
  simpleSignupAuthority:
    "https://fecaidpdev.b2clogin.com/tfp/fecaidpdev.onmicrosoft.com/B2C_1_feca_signup_v2",
  signupSigninAuthority:
    "https://fecaidpdev.b2clogin.com/tfp/fecaidpdev.onmicrosoft.com/B2C_1_feca_signupsignin_v2",
  signupAuthority:
    "https://fecaidpdev.b2clogin.com/tfp/fecaidpdev.onmicrosoft.com/B2C_1_signup_wth_provider_creation",
  editProfileAuthority:
    "https://fecaidpdev.b2clogin.com/tfp/fecaidpdev.onmicrosoft.com/B2C_1_feca_profile_v2",
  passwordResetAuthority:
    "https://fecaidpdev.b2clogin.com/tfp/fecaidpdev.onmicrosoft.com/B2C_1_feca_passwordreset",
  authorizationAuthority:
    "https://fecaidpdev.b2clogin.com/fecaidpdev.onmicrosoft.com/oauth2/v2.0/token?p=B2C_1_feca_signupsignin_v2",
  scopes: {
    userImpersonation:
      "https://fecaidpdev.onmicrosoft.com/api/user_impersonation",
    noop:
      "https://fecaidpdev.onmicrosoft.com/dee90d17-512a-4f7d-a661-85c564c31ba6/noop",
  },
};

export function getMsalConfig(): MsalConfig {
  return getEnvironment() === "DEV" ? devMsalConfig : prodMsalConfig;
}
