import {
  loadAccount,
  setLoadUserError,
  setAccount,
} from "./../slices/auth.slice";
import { Action } from "@reduxjs/toolkit";
import { ofType, ActionsObservable } from "redux-observable";
import { mergeMap, catchError, mergeMapTo } from "rxjs/operators";
import { of } from "rxjs";
import ajax from "../../ajax";
import { mapUserResponseToIUser } from "./httpResponseTypes/getUserResponseType";
import { mapProviderResponseToIProvider } from "./httpResponseTypes/getProviderResponseType";
import { setProviders } from "../slices/provider-records.slice";

export const loadUserEpic = (action$: ActionsObservable<Action<string>>) =>
  action$.pipe(
    ofType(loadAccount.type),
    mergeMapTo(
      ajax("account").pipe(
        mergeMap((ajax) =>
          of(
            setAccount({
              user: mapUserResponseToIUser(ajax.response.user),
              provider: ajax.response.provider?.id ?? null,
            }),
            setProviders(
              [
                ...ajax.response.user.providers?.map(
                  mapProviderResponseToIProvider
                ),
                ajax.response.provider
                  ? mapProviderResponseToIProvider(ajax.response.provider)
                  : undefined,
              ].filter((p) => Boolean(p))
            )
          )
        ),
        catchError((e) => of(setLoadUserError(e.message)))
      )
    )
  );
