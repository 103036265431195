import { RootState } from "./../store";
import { of, from } from "rxjs";
import { mapProviderResponseToIProvider } from "./httpResponseTypes/getProviderResponseType";
import { mergeMapTo, mergeMap, map, filter, mergeAll } from "rxjs/operators";
import { PayloadAction } from "@reduxjs/toolkit";
import { Action } from "@reduxjs/toolkit";
import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import {
  loadProvidersList,
  setProvidersList,
  setLoadingActiveProvider,
  setActiveProviderId,
  approveProvider,
  setApprobation,
} from "../slices/admin.slice";
import _ajax from "../../ajax";
import { setProviders } from "../slices/provider-records.slice";

export const approveProviderEpic = (
  action$: ActionsObservable<ReturnType<typeof approveProvider>>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(approveProvider.type),
    mergeMap(({ payload }) =>
      _ajax("account/approve", { params: { provider_id: payload } }).pipe(
        mergeMapTo(
          of(
            setProviders({
              ...state$.value.providers.data[payload],
              approved: new Date(),
            }),
            setApprobation({
              loading: false,
              success: "The selected provider was approved successfully",
              error: "",
            })
          )
        )
      )
    )
  );

export const loadProvidersListEpic = (
  action$: ActionsObservable<Action<string>>
) =>
  action$.pipe(
    ofType(loadProvidersList.type),
    mergeMapTo(
      _ajax("providers", { params: { limited: true } }).pipe(
        map((ajax) => setProvidersList(ajax.response.data))
      )
    )
  );

export const loadActiveProviderEpic = (
  action$: ActionsObservable<PayloadAction<number>>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(setActiveProviderId.type),
    filter(
      (action) => state$.value.providers.data[action.payload] === undefined
    ),
    mergeMap((action) =>
      from([
        of(
          setLoadingActiveProvider({
            loading: true,
            success: "success",
            error: "",
          })
        ),
        _ajax(`providers/${action.payload}`).pipe(
          mergeMap((ajax) =>
            of(
              setProviders(mapProviderResponseToIProvider(ajax.response)),
              setLoadingActiveProvider({
                loading: false,
                success: "success",
                error: "",
              })
            )
          )
        ),
      ])
    ),
    mergeAll()
  );
