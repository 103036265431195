import * as React from "react";
import { createCtx } from "../utils";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import blue from "@material-ui/core/colors/blue";
import gray from "@material-ui/core/colors/grey";
import { CssBaseline, useMediaQuery } from "@material-ui/core";

import bg1 from "../assets/images/background/1.png";
import bg1dark from "../assets/images/background/1.dark.png";
import bg2 from "../assets/images/background/2.png";
import bg2dark from "../assets/images/background/2.dark.png";
import bg3 from "../assets/images/background/3.png";
import bg3dark from "../assets/images/background/3.dark.png";
import bg4 from "../assets/images/background/4.png";
import bg4dark from "../assets/images/background/4.dark.png";
import bg5 from "../assets/images/background/5.png";
import bg5dark from "../assets/images/background/5.dark.png";
import bg6 from "../assets/images/background/6.png";
import bg6dark from "../assets/images/background/6.dark.png";
import bg7 from "../assets/images/background/7.png";
import bg7dark from "../assets/images/background/7.dark.png";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    scrollbar: CSSProperties;
    backgrounds: {
      1: string;
      2: string;
      3: string;
      4: string;
      5: string;
      6: string;
      7: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    scrollbar?: CSSProperties;
    backgrounds: {
      1: string;
      2: string;
      3: string;
      4: string;
      5: string;
      6: string;
      7: string;
    };
  }
}

interface IAppThemeProviderProps {}
interface IAppThemeCtx {
  toggleDarkMode: () => void;
  preferedColorScheme: "dark" | "light";
}

const [useCtx, Provider] = createCtx<IAppThemeCtx>();
export const useThemeProvider = useCtx;

const AppThemeProvider: React.FunctionComponent<IAppThemeProviderProps> = (
  props
) => {
  const prefersDarkModeMediaQuery = useMediaQuery(
    "(prefers-color-scheme: dark)"
  );
  const preferedColorSchemeKey = "prefered-color-scheme";

  const [preferedColorSchemeLS, setpreferedColorSchemeLS] = React.useState<
    "dark" | "light" | null
  >(
    window.localStorage.getItem(preferedColorSchemeKey) === "dark"
      ? "dark"
      : window.localStorage.getItem(preferedColorSchemeKey) === "light"
      ? "light"
      : null
  );

  const preferedColorScheme = React.useMemo(
    () =>
      preferedColorSchemeLS ?? (prefersDarkModeMediaQuery ? "dark" : "light"),
    [preferedColorSchemeLS, prefersDarkModeMediaQuery]
  );

  const value: IAppThemeCtx = {
    preferedColorScheme: preferedColorScheme,
    toggleDarkMode: () => {
      const newPreferedColorScheme =
        preferedColorScheme === "dark" ? "light" : "dark";
      window.localStorage.setItem(
        preferedColorSchemeKey,
        newPreferedColorScheme
      );
      setpreferedColorSchemeLS(newPreferedColorScheme);
    },
  };

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        backgrounds: {
          1:
            preferedColorScheme === "light" ? `url(${bg1})` : `url(${bg1dark})`,
          2:
            preferedColorScheme === "light" ? `url(${bg2})` : `url(${bg2dark})`,
          3:
            preferedColorScheme === "light" ? `url(${bg3})` : `url(${bg3dark})`,
          4:
            preferedColorScheme === "light" ? `url(${bg4})` : `url(${bg4dark})`,
          5:
            preferedColorScheme === "light" ? `url(${bg5})` : `url(${bg5dark})`,
          6:
            preferedColorScheme === "light" ? `url(${bg6})` : `url(${bg6dark})`,
          7:
            preferedColorScheme === "light" ? `url(${bg7})` : `url(${bg7dark})`,
        },
        palette: {
          type: preferedColorScheme,
          common: {},
          primary: {
            main: "#225DA2",
            light: "#6484AA",
            dark: "#074187",
            contrastText: "#f2f2f2",
          },
        },
        scrollbar: {
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
            backgroundColor: gray[50],
          },
          "&::-webkit-scrollbar": {
            width: 6,
            backgroundColor: gray[50],
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: blue[700],
          },
        },
      }),
    [preferedColorScheme]
  );

  return (
    <Provider value={value}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </Provider>
  );
};

export default AppThemeProvider;
