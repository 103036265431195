import { IProvider } from "../../../Entities/Models/IProvider";
import { StateShortType } from "../../../Entities/Models/IProvider";

type GetProviderResponseType = {
  id: number;
  name: string;
  number?: string | null;
  eulaAcceptedDate?: string | null;
  eulaToAccept?: string | null;
  stripeCustomerId: string;
  hasEncryptedData: boolean;
  approved?: Date | null;
  npi?: string | null;
  taxonomy?: string | null;
  phone: string;
  address: string;
  city: string;
  state: StateShortType;
  zipcode: string;
  typeId: number;
  type: "Medical" | "Billing";
  createdByUserId: number;
  createdDate: string;
  modifiedByUserId?: number | null;
  modifiedDate?: string | null;
  deletedByUserId?: number | null;
  deletedDate?: string | null;
};

export const mapProviderResponseToIProvider = (
  response: GetProviderResponseType
): IProvider => ({
  ...response,
  eulaAcceptedDate: response.eulaAcceptedDate
    ? new Date(response.eulaAcceptedDate)
    : undefined,
  approved: response.approved ? new Date(response.approved) : undefined,
  NPI: response.npi,
});

export default GetProviderResponseType;
