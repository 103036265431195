import {
  approveProviderEpic,
  loadActiveProviderEpic,
  loadProvidersListEpic,
} from "./admin.epic";
import { loadUserEpic } from "./auth.epic";
import { combineEpics } from "redux-observable";
import {
  archiveProviderEpic,
  createProviderEpic,
  updateProviderEpic,
} from "./provider-records.epic";

export default combineEpics(
  loadUserEpic,
  loadProvidersListEpic,
  loadActiveProviderEpic,
  archiveProviderEpic,
  approveProviderEpic,
  createProviderEpic,
  createProviderEpic,
  updateProviderEpic
);
