import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { filter } from "ramda";
import ActionStatusType from "./actionStatusType";

type ProvidersRecord = Record<
  number,
  {
    id: number;
    name: string;
    number: string | null;
    type: "Billing" | "Medical";
  }
>;

export type AdminState = {
  activeProviderId: number | null;
  providersList: ProvidersRecord;
  loadingActiveProvider: ActionStatusType;
  loadingProvidersList: boolean;
  approbation: ActionStatusType;
};

const initialState: AdminState = {
  activeProviderId: null,
  providersList: {},
  loadingActiveProvider: {
    loading: false,
    success: "",
    error: "",
  },
  loadingProvidersList: false,
  approbation: {
    loading: false,
    success: "",
    error: "",
  },
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    loadProvidersList(state) {
      state.loadingProvidersList = true;
    },
    setActiveProviderId(state, action: PayloadAction<number>) {
      state.activeProviderId = action.payload;
    },
    setLoadingActiveProvider(state, action: PayloadAction<ActionStatusType>) {
      state.loadingActiveProvider = action.payload;
    },
    setProvidersList(
      state,
      action: PayloadAction<
        Array<{ id: number; name: string; number: string | null }>
      >
    ) {
      state.loadingProvidersList = false;
      state.providersList = action.payload.reduce(
        (record, provider) => ({ ...record, [provider.id]: provider }),
        {}
      );
    },
    removeSimpleProviders(state, action: PayloadAction<number[] | number>) {
      const providerIdsToRemove = [action.payload].flat();
      state.providersList = filter(
        (provider) => !providerIdsToRemove.includes(provider.id),
        state.providersList
      );
    },
    approveProvider(state, action: PayloadAction<number>) {
      state.approbation.loading = true;
    },
    setApprobation(state, action: PayloadAction<ActionStatusType>) {
      state.approbation.loading = action.payload.loading;
      state.approbation.success = action.payload.success;
      state.approbation.error = action.payload.error;
    },
  },
});

export const {
  loadProvidersList,
  setActiveProviderId,
  setLoadingActiveProvider,
  setProvidersList,
  removeSimpleProviders,
  approveProvider,
  setApprobation,
} = adminSlice.actions;
export default adminSlice.reducer;
