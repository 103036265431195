import { from } from "rxjs";
import { ajax, AjaxRequest } from "rxjs/ajax";
import { mergeMap } from "rxjs/operators";
import auth from "./Services/auth";
import { getApiUrl } from "./utils";
import qs from "qs";

export type AjaxOptions = {
  params?: any;
  body?: any;
  headers?: any;
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
};

function mapOptionsToRequest(
  url: string,
  options: AjaxOptions,
  accessToken?: string
): AjaxRequest {
  return {
    url:
      getApiUrl(url) +
      (options.params
        ? "?" + qs.stringify(options.params, { arrayFormat: "brackets" })
        : ""),
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken ? "Bearer " + accessToken : undefined,
      ...options.headers,
    },
    body: options.body,
    method: options.method ?? "GET",
  };
}

function _ajax(endpoint: string, options: AjaxOptions = {}) {
  if (auth().isLoggedIn()) {
    return from(auth().getAccessToken()).pipe(
      mergeMap((accessToken) =>
        ajax(mapOptionsToRequest(endpoint, options, accessToken))
      )
    );
  }

  return ajax(mapOptionsToRequest(endpoint, options));
}

export default _ajax;
