import Axios from "axios";
import { getEnvironment } from "../utils";
import auth from "./auth";

export default async function http() {
  const token = auth().isLoggedIn() ? await auth().getAccessToken() : undefined;
  const providerId = window.localStorage.getItem("selected-provider-id");
  const http = Axios.create({
    baseURL: process.env.REACT_APP_LOCAL_FUNC
      ? "http://localhost:5000/api/"
      : getEnvironment() === "DEV"
      ? "https://api-dev.medavise.io/api/"
      : "https://api.medavise.io/api/",

    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    params: {
      provider_id: providerId ?? undefined,
    },
  });
  return http;
}
