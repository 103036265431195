import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../Entities/Models/IProvider";
import auth from "../../Services/auth";

export type AuthState = {
  authenticated: boolean;
  user: IUser | null;
  loading: boolean;
  loadUserError: string | null;
  provider: number | null;
};

const initialState: AuthState = {
  authenticated: auth().isLoggedIn(),
  user: null,
  loading: false,
  loadUserError: null,
  provider: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loadAccount(state) {
      state.loading = true;
    },
    setAccount(
      state,
      action: PayloadAction<{ user: IUser; provider: number | null }>
    ) {
      state.user = action.payload.user;
      state.provider = action.payload.provider;
      state.loading = false;
    },
    setLoadUserError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.loadUserError = action.payload;
    },
  },
});

export const { setAccount, loadAccount, setLoadUserError } = authSlice.actions;

export default authSlice.reducer;
