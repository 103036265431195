import { AuthState } from "./store/slices/auth.slice";
import { RootState } from "./store/store";
import { useSelector } from "react-redux";
import { IProvider, IUser } from "./Entities/Models/IProvider";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { dateFormat } from "./constants";
import {
  take,
  slice,
  adjust,
  toUpper,
  join,
  concat,
  startsWith,
  tail,
} from "ramda";
import numeral from "numeral";

export function createCtx<A>() {
  const ctx = React.createContext<A | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(ctx);
    if (!c) throw new Error("useCtx must be inside a Provider with a value");
    return c;
  }
  return [useCtx, ctx.Provider] as const; // make TypeScript infer a tuple, not an array of union types
}

export function formatdate(date: Date, format: string = dateFormat): string {
  return new DateFnsUtils().format(date, format);
}

export const shortenText = (s: string, length: number = 200) =>
  s.length > length ? take(length, s) + "..." : s;

export function formatCurrency(
  value: string | number,
  format: string = "$ 0,0.00"
) {
  return numeral(value).format(format);
}

export function formatPhone(value: string) {
  return `(${slice(0, 3, value)}) ${slice(3, 6, value)} - ${slice(
    6,
    10,
    value
  )}`;
}

export function floatFromCurrency(currency: string): number {
  return parseFloat(currency.replace(/[^(\d.)]/g, ""));
}

export function capitalizeFirstLetter(s: string): string {
  if (s.length === 0) {
    return s;
  }
  const array = s.split("");
  const modified = adjust(0, toUpper, array);
  return join("", modified);
}

export function getEnvironment(): "DEV" | "PROD" {
  if (
    window.location.host.includes("localhost") ||
    window.location.host.includes("dev.fecabill.com") ||
    window.location.host.includes("dev.medavise.io")
  ) {
    return "DEV";
  }

  return "PROD";
}

export function urlBase64ToUint8Array(base64String: string) {
  var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line no-useless-escape
  var base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function getApiUrl(endpoint: string) {
  const baseURL = process.env.REACT_APP_LOCAL_FUNC
    ? "http://localhost:5000/api/"
    : getEnvironment() === "DEV"
    ? "https://api-dev.medavise.io/api/"
    : "https://api.medavise.io/api/";

  return concat(baseURL, startsWith("/", endpoint) ? tail(endpoint) : endpoint);
}

export function getActiveProvider(state: RootState) {
  const activeProviderId =
    state.auth.user?.type === "ProviderContact"
      ? state.auth.provider
      : state.admin.activeProviderId;

  const activeProvider = activeProviderId
    ? state.providers.data[activeProviderId]
    : null;
  return activeProvider;
}

export function useActiveProviderHook(): IProvider | null {
  const state = useSelector<RootState, RootState>((state) => state);
  return getActiveProvider(state);
}

export function userIsAdmin(user?: IUser | null): boolean {
  return ["ProviderAdmin", "Admin"].includes(user?.type ?? "");
}

export function useIsAdminHook(user?: IUser | null): boolean {
  const state = useSelector<RootState, AuthState>((state) => state.auth);
  return userIsAdmin(state.user);
}
