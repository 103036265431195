import { PostProviderRequestType } from "./httpResponseTypes/postProviderRequestType";
import { mapProviderResponseToIProvider } from "./httpResponseTypes/getProviderResponseType";
import { of, from } from "rxjs";
import { mergeMap, mapTo, mergeAll } from "rxjs/operators";
import { ofType } from "redux-observable";
import { PayloadAction } from "@reduxjs/toolkit";
import { ActionsObservable } from "redux-observable";
import {
  archiveProvider,
  createProvider,
  removeProviders,
  setProviders,
  setCreateProviderStatus,
  updateProvider,
  setUpdateProviderStatus,
} from "../slices/provider-records.slice";
import _ajax from "../../ajax";
import { removeSimpleProviders } from "../slices/admin.slice";

export const updateProviderEpic = (
  action$: ActionsObservable<ReturnType<typeof updateProvider>>
) =>
  action$.pipe(
    ofType(updateProvider.type),
    mergeMap(({ payload }) =>
      _ajax(`providers/${payload.id}`, {
        method: "PUT",
        body: payload.data,
      }).pipe(
        mergeMap((ajax) =>
          of(
            setProviders(mapProviderResponseToIProvider(ajax.response)),
            setUpdateProviderStatus({
              loading: false,
              success: "Provider information was updated sucessfully",
              error: "",
              updatedProviderId: ajax.response.id,
            })
          )
        )
      )
    )
  );

export const createProviderEpic = (
  action$: ActionsObservable<PayloadAction<PostProviderRequestType>>
) =>
  action$.pipe(
    ofType(createProvider.type),
    mergeMap(({ payload }) =>
      _ajax("account", { method: "POST", body: payload }).pipe(
        mergeMap((ajax) =>
          of(
            setProviders(
              mapProviderResponseToIProvider(ajax.response.provider)
            ),
            setCreateProviderStatus({
              loading: false,
              success: "Provider was created sucessfully",
              error: "",
              createdProviderId: ajax.response.provider.id,
            })
          )
        )
      )
    )
  );

export const archiveProviderEpic = (
  action$: ActionsObservable<PayloadAction<number>>
) =>
  action$.pipe(
    ofType(archiveProvider.type),
    mergeMap(({ payload }) =>
      from([
        of(removeSimpleProviders(payload)),
        _ajax(`providers/${payload}`, { method: "DELETE" }).pipe(
          mapTo(removeProviders(payload))
        ),
      ])
    ),
    mergeAll()
  );
